import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import moment from 'moment'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEventEditScheduledEmailView',
  computed: {
    ...mapState('email', ['emails', 'scheduled_emails'])
  }
})
export default class GtrEventEditScheduledEmailView extends GtrSuper {
  data () {
    return {
      loading: false,
      submitting: false,
      criteria: false,
      emailsToSchedule: [],
      scheduledEmail: {
        email: null,
        user_type: 'Participant',
        datetime: null,
        cc: '',
        bcc: '',
        criteria: {
          visible: false,
          global_type: '*',
          type: '*',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        }
      }
    }
  }

  @Watch('emails')
  onEmailsChange (payload: any) {
    payload.map((email: any) => {
      const words = email.key.split('_').filter(word => word !== '')
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }
      const name = words.join(' ')
      this.$data.emailsToSchedule.push({
        text: name,
        value: email.key
      })
    })
  }

  get scheduledEmailChoices (): any[] {
    const filteredEmails = this.$data.emailsToSchedule.filter((email) => email.value !== 'admin_cancellation_notification' && email.value !== 'admin_registration_notification')
    return filteredEmails.sort((a, b) => a.text > b.text)
  }

  @Watch('scheduled_emails')
  onScheduledEmailsChange (payload: any) {
    const email = payload.filter((email: any) => email.value.uuid === this.$route.params.email_uuid).pop()
    if (email) {
      this.$data.scheduledEmail.email = email.value.email_name
      this.$data.scheduledEmail.user_type = email.value.user_type || 'Participant'
      this.$data.scheduledEmail.datetime = this.convertDatetimeToLocalTimezone(email.value.send_time)
      this.$data.scheduledEmail.criteria = this.convertOldShowIfToNewFormat(email.value.criteria)
      if (this.$data.scheduledEmail?.criteria?.type !== '*') {
        this.$data.scheduledEmail.criteria.visible = true
      }
    }
  }

  convertOldShowIfToNewFormat (payload: any) {
    const mergedShowIfObject = Object.assign({}, {
      global_type: '*',
      visible: false,
      type: '*',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }, payload)

    if (payload?.type === 'grouping') {
      mergedShowIfObject.global_type = 'multiple'
    } else if (['field_criteria', 'option_criteria'].includes(payload?.type)) {
      mergedShowIfObject.global_type = 'single'
    }
    return mergedShowIfObject
  }

  async mounted () {
    this.$data.loading = true
    const event_uuid = this.$route.params.event_uuid
    const promises = [this.$store.dispatch('email/fetchScheduledEmails', event_uuid), this.$store.dispatch('email/fetchEmails', event_uuid)]
    await Promise.all(promises)
    this.$data.loading = false
  }

  handleChangeUseCriteria (checked: boolean) {
    this.$data.scheduledEmail.criteria = {
      visible: checked,
      global_type: '*',
      type: '*',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }
  }

  async submit () {
    const form = (this.$refs.editScheduledEmailForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          schedule_uuid: this.$route.params.email_uuid,
          data: {
            criteria: this.$data.scheduledEmail.criteria,
            user_type: this.$data.scheduledEmail.user_type,
            email_name: this.$data.scheduledEmail.email,
            send_time: this.convertDatetimeToUTC(this.$data.scheduledEmail.datetime),
            cc: this.$data.scheduledEmail.cc,
					  bcc: this.$data.scheduledEmail.bcc
          }
        }
        await this.$store.dispatch('email/updateScheduledEmail', payload)
        Container.get(Notification).success('Scheduled email successfully edited.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  get optionGroupOptionsByUUID () {
    const items: any[] = []
    for (let i = 0; i < this.$data.optionGroups.length; i++) {
      items[this.$data.optionGroups[i].uuid] = []
      for (let x = 0; x < this.$data.optionGroups[i].options.length; x++) {
        items[this.$data.optionGroups[i].uuid].push({
          text: this.$data.optionGroups[i].options[x].name,
          value: this.$data.optionGroups[i].options[x].uuid
        })
      }
      items[this.$data.optionGroups[i].uuid].sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
    }
    return items
  }
}
